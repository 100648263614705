import Helmet from "react-helmet"
import React from "react"
import LpScripts from "@tightrope/lpscripts"
import 'bootstrap/dist/css/bootstrap.min.css'

export default function HomepageLayout({children}) {
  return(
    <section>
      <Helmet>
        <link rel="icon" type="image/png" href="/img/favicon.png" />
      </Helmet>
      {children}
      <LpScripts></LpScripts>
    </section>
  )
}
